import type {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';

export function castToUntypedFormGroup(
  form: AbstractControl,
): UntypedFormGroup {
  return form as UntypedFormGroup;
}

export function castToUntypedFormArray(
  array: AbstractControl,
): UntypedFormArray {
  return array as UntypedFormArray;
}
