import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
/**
 * at least one validator musst be required
 * example: { validator: atLeastOne(Validators.required, ['email','telefone']) }
 * @param  {ValidatorFn} validator
 * @param  {string[]=null} controls
 */
export function atLeastOne(
  validator: ValidatorFn,
  controls: string[] = null
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const group = control as FormGroup;

    if (!controls) {
      controls = Object.keys(group.controls);
    }

    const hasAtLeastOne =
      group && group.controls && controls.some((k) => !validator(group.get(k)));

    return hasAtLeastOne
      ? null
      : {
          atLeastOne: true,
        };
  };
}
